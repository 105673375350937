<template>
  <div>
    <el-form :inline="true" :model="searchForm">
      <div class="row-bg">
        <!-- 
        <el-form-item>
          <el-button type="primary" @click="getListHandler">查询</el-button>
          <el-button @click="resetForm">重置</el-button>
        </el-form-item>
        <el-button style="float: right" type="primary" @click="openSelectModal"
          >学员报名</el-button> -->
        <el-row :gutter="24">
          <el-col :lg="5" :md="24">
            <el-form-item label="商户名称：">
              <el-input
                clearable
                v-model="searchForm.merchantName"
                placeholder="请输入"
                class="w100"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="5" :md="24">
            <el-input
              clearable
              v-model="searchForm.userName"
              placeholder="学员姓名"
              class="w100"
            ></el-input>
          </el-col>
          <el-col :lg="5" :md="24">
            <el-input
              clearable
              v-model="searchForm.courseName"
              placeholder="课程名称"
              class="w100"
            ></el-input>
          </el-col>
          <template v-if="advanced">
            <el-col :lg="5" :md="24">
              <el-select
                clearable
                v-model="searchForm.status"
                placeholder="学员状态"
              >
                <el-option
                  v-for="item in options"
                  :key="item.key"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-col>
          </template>
          <el-form-item>
            <el-button type="primary" @click="getListHandler">查询</el-button>
            <el-button @click="resetForm">重置</el-button>
          </el-form-item>
          <!-- <search-query
            @updateView="updateView"
            @clearQuery="clearQuery"
            @searchQuery="$emit('search', searchForm)"
          ></search-query> -->
        </el-row>
      </div>
    </el-form>

    <!-- 表格 -->
    <el-card shadow="never" style="overflow-y: scroll; height: 600px">
      <!-- 头部 -->
      <div slot="header" class="flex" style="align-items: center">
        <span>商户学员课时统计</span>
        <div class="flex_1"></div>
        <!-- <el-button style="float: right" type="primary" @click="openSelectModal"
          >学员报名</el-button
        > -->
        <text-button
          icon="el-icon-refresh"
          contentTit="刷新"
          @click.native="search"
        ></text-button>
        <text-button
          icon="el-icon-setting"
          contentTit="列设置"
          :colSetting="colSetting"
          @update="update"
          :ispopover="true"
          :baseColSetting="baseColSetting"
        ></text-button>
      </div>
      <el-table-bar fixed :static="true">
        <el-table
          :default-sort="{ prop: 'open_date', order: 'descending' }"
          :key="tableKey"
          fit
          :highlight-current-row="true"
          :data="tableData"
        >
          <el-table-column
            v-for="item of defaultHeader"
            :key="item.label"
            :label="item.label"
            :align="item.align ? item.align : 'left'"
            :min-width="item.width ? item.width : 'auto'"
            :sortable="item.sortable ? item.sortable : false"
            :prop="item.prop"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <template v-if="item.label === '状态'">
                <span :class="statusOptions[scope.row.status]">
                  {{ scope.row.status }}
                </span>
              </template>
              <template v-else-if="item.label === '课程名称'">
                <el-tooltip :content="scope.row.courseName" placement="top">
                  <span class="text-oneline">
                    {{ scope.row.courseName }}
                  </span>
                </el-tooltip>
              </template>
              <!-- <template v-else-if="item.label === '课程名称'"> </template> -->

              <template v-else-if="item.label === '操作'">
                <el-button
                  type="text"
                  style="margin-right: 10px"
                  @click="openDetailModal(scope.row)"
                  >课消明细</el-button
                >
                <el-popconfirm
                  @onConfirm="confirmStatus(scope.row)"
                  @confirm="confirmStatus(scope.row)"
                  :title="`确定要为该学员退课吗？`"
                >
                  <template v-if="scope.row.status == '在读'">
                    <el-button type="text" slot="reference"> 退课 </el-button>
                  </template>
                </el-popconfirm>
              </template>
              <span v-else>{{ scope.row[item.prop] }}</span>
            </template>
          </el-table-column>
        </el-table>
      </el-table-bar>

      <!-- <el-table :size="size" :border="true" :data="tableData">
        <el-table-column align="center" prop="userName" label="学员姓名" />
        <el-table-column align="center" prop="courseName" label="课程名称">
          <template slot-scope="scope">
            <el-tooltip :content="scope.row.courseName" placement="top">
              <span class="text-oneline">
                {{ scope.row.courseName }}
              </span>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          sortable
          prop="createTime"
          label="报名日期"
          width="150"
        />
        <el-table-column align="center" prop="className" label="所在班级">
          <template slot-scope="scope">
            <el-tooltip :content="scope.row.className" placement="top">
              <span class="text-oneline">
                {{ scope.row.className }}
              </span>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="amount" label="剩余课时" />
        <el-table-column align="center" prop="usedAmount" label="已课消" />
        <el-table-column align="center" prop="status" label="状态">
          <template slot-scope="scope">
            <span :class="statusOptions[scope.row.status]">
              {{ scope.row.status }}
            </span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="action" label="操作">
          <template slot-scope="scope">
            <el-button
              type="text"
              style="margin-right: 10px"
              @click="openDetailModal(scope.row)"
              >课消明细</el-button
            >
            <el-popconfirm
              @onConfirm="confirmStatus(scope.row)"
              @confirm="confirmStatus(scope.row)"
              :title="`确定要为该学员退课吗？`"
            >
              <template v-if="scope.row.status == '在读'">
                <el-button type="text" slot="reference"> 退课 </el-button>
              </template>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table> -->
      <el-pagination
        :size="size"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageInfo.pageNum"
        :page-sizes="[10, 20, 30, 50, 100]"
        :page-size="pageInfo.pageSize"
        :total="pageInfo.total"
        layout="total, sizes, prev, pager, next, jumper"
        background
      >
      </el-pagination>
    </el-card>

    <SelectStudentModal
      ref="select"
      title="选择"
      @openAddModal="openAddModal"
    />
    <AddStudentModal ref="add" title="添加" @submit="addMember" />
    <EditModal ref="edit" title="编辑" :item="item" @submit="editMember" />
    <StudentCourseDetailModal
      :searchForm="searchForms"
      ref="detail"
      title="学员课程课消明细"
    />
  </div>
</template>
<script>
import { addStudent, editStudent } from "@/api/member/student";

import { getCourseMemberList, leaveCourse } from "@/api/businesscourse/course";
import AddStudentModal from "./components/AddStudentModal";
import EditModal from "@/views/member/EditModal";
import StudentCourseDetailModal from "./components/StudentCourseDetailModal";
import SelectStudentModal from "./components/SelectStudentModal";
import TextButton from "@/components/TextButton";


const baseColSetting = [
  {
    label: "商户名称",
    prop: "merchantName",
    state: true,
    align: "left",
    width: "100",
  },
  {
    label: "学员姓名",
    prop: "userName",
    state: true,
    align: "center",
    width: "100",
  },
  {
    label: "手机号",
    prop: "tel",
    state: true,
    align: "center",
    width: "100",
  },
  {
    label: "课程名称",
    prop: "courseName",
    state: true,
    align: "center",
    width: "100",
  },
  {
    label: "报名日期",
    prop: "createTime",
    state: true,
    sortable: true,
    align: "center",
    width: "150",
  },
  {
    label: "所在班级",
    prop: "className",
    state: true,
    // sortable: true,
    align: "center",
    width: "100",
  },
  {
    label: "剩余课时",
    prop: "amount",
    state: true,
    // sortable: true,
    align: "center",
    width: "100",
  },
  {
    label: "已课消",
    prop: "usedAmount",
    state: true,
    // sortable: true,
    align: "center",
    width: "100",
  },
  {
    label: "状态",
    prop: "state",
    state: true,
    align: "center",
    width: "100",
  },
  {
    label: "操作",
    state: true,
    align: "center",
    width: "130",
  },
];
export default {
  components: {
    AddStudentModal,
    EditModal,
    StudentCourseDetailModal,
    SelectStudentModal,
    TextButton
  },
  data() {
    return {
      tableKey:1,
      advanced: false,
      size: "small",
      item: {},
      title: "",
      tableData: [
        {
          merchantName:'珀莱雅',
          userName:'王小胡',
          tel:'1111111',
          courseName:'s',
          className:'1年级',
          createTime:'2020-1-1 12-31',
          amount:"2",
          usedAmount:"2",
          state:'开启',
        }
      ],
      detailTableData: [],
      detailPageInfo: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
        needCount: true,
      },
      pageInfo: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
        needCount: true,
      },
      searchForm: { userName: null, courseName: null, status: null },
      searchForms: {},
      statusOptions: {
        //'未开始','在读','结课','退课'
        未开始: "start-class",
        在读: "read-class",
        结课: "end-class",
        退课: "quit-class",
      },
      options: [
        {
          value: "未开始",
          label: "未开始",
        },
        {
          value: "在读",
          label: "在读",
        },
        {
          value: "结课",
          label: "结课",
        },
        {
          value: "退课",
          label: "退课",
        },
      ],
      colSetting: JSON.parse(JSON.stringify(baseColSetting)), // 表格展示列
      baseColSetting: JSON.parse(JSON.stringify(baseColSetting)),
    };
  },
  
  created() {
    this.getListHandler();
  },
  computed: {
    defaultHeader() {
      return this.colSetting.filter((item) => item.state);
    },
  },
  methods: {
    /**
     * 获取列表
     */
    async getListHandler() {
      let responseData = await getCourseMemberList({
        pageNum: this.pageInfo.pageNum,
        pageSize: this.pageInfo.pageSize,
        needCount: this.pageInfo.needCount,
        ...this.searchForm,
      });
      if (responseData && responseData.state == "success") {
        this.tableData = responseData.body.list;
        this.pageInfo.total = responseData.body.total;
      }
    },
    /**
     * 获取详情
     */
    async getDetail(id) {
      return this.tableData.filter((item) => item.id == id)[0];
    },
    confirmStatus(item) {
      const { userId, courseId } = item;
      leaveCourse({
        userId,
        courseId,
      }).then((res) => {
        if (res.state == "success") {
          this.$message.success("退课成功");
          this.getListHandler();
        }
      });
    },
    // 查询
    getListHandler(){

    },
    /**
     * 重置表单
     */
    resetForm() {
      this.pageInfo.pageNum = 1;
      this.pageInfo.pageSize = 10;
      this.searchForm = {
        userName: null,
        courseName: null,
        status: null,
      };
      // this.getListHandler();
    },
    /**
     * 页码改变
     */
    handleCurrentChange(page) {
      this.pageInfo.pageNum = page;
      // this.getListHandler();
    },
    /**
     * 打开添加弹窗
     */
    openAddModal() {
      this.$refs.add.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs.add.initData();
      });
    },
    /**
     * 打开报名
     */
    openSelectModal() {
      this.$refs.select.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs.select.initData();
      });
    },
    /**
     * 打开详情
     */
    openDetailModal(item) {
      this.searchForms = {
        ...item,
      };
      this.$nextTick(() => {
        this.$refs.detail.drawer = true;
      });
    },
    /**
     * pageSize改变
     */
    handleSizeChange(size) {
      this.pageInfo.pageSize = size;
      // this.getListHandler();
    },
    /**
     * 添加成员
     */
    // async addMember(value) {
    //   const { name, tel, pwd, loginName, state } = value;
    //   const response = await addStudent({ name, tel, pwd, loginName, state });
    //   if (response.state == "success") {
    //     // this.pageInfo.pageNum = 1;
    //     // this.pageInfo.pageSize = 10;
    //     this.$message.success("添加成功");
    //     // this.getListHandler();
    //     this.$refs.add.dialogVisible = false;
    //   }
    // },
    // async editMember(value) {
    //   const { id, name, tel, pwd, state } = value;
    //   const response = await editStudent({
    //     id,
    //     name,
    //     tel,
    //     pwd,
    //     state,
    //   });
    //   if (response.state == "success") {
    //     this.$message.success("编辑成功");
    //     //   this.pageInfo.pageNum = 1;
    //     //   this.pageInfo.pageSize = 10;
    //     // this.getListHandler();
    //     this.$refs.edit.dialogVisible = false;
    //   } else {
    //     this.$message.warning("编辑失败");
    //   }
    // },
    /**
     * 刷新
     */
    search() {
      this.pageInfo.pageNum = 1;
      // this.getListHandler();
    },
    update(data) {
      this.tableKey = this.tableKey + 1;
      if (data.lenght !== 0) {
        this.colSetting.forEach((item) => {
          item.state = false;
          data.forEach((ele) => {
            if (ele === item.label && !item.state) {
              item.state = true;
            }
          });
        });
      } else {
        this.colSetting = [];
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.outer-style {
  height: calc(100% - 50px);
  overflow: auto;
}

.start-class,
.end-class,
.read-class,
.quit-class {
  display: inline-block;
  position: relative;
}
.start-class::before,
.end-class::before,
.read-class::before,
.quit-class::before {
  content: "";
  position: absolute;
  left: -20px;
  top: 8px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.start-class::before {
  background-color: gray;
}
.read-class::before {
  background-color: blue;
}
.quit-class::before {
  background-color: red;
}
.end-class::before {
  background-color: green;
}
.outer-style {
  height: calc(100% - 50px);
  overflow: auto;
}
@import "../../style/container.scss";

/deep/ .el-form--inline .el-form-item {
  margin-right: 0;
}
@import "../../style/container";
</style>
